/* eslint-disable */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link } from '@reach/router';
import { useSelector, useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { I18n } from 'react-redux-i18n';
import LogoSVG from '../reusable/Logo_SVG';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus-icon.svg';
import { ReactComponent as NotificationsIcon } from '../../assets/icons/notifications.svg';
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout.svg';

import { selectNumberOfUnreadReports } from '../../store/reports/selectors';
import { selectRoutesList } from '../../store/routes/selectors';
import { addCredits, logout, dismissUserNotification } from '../../store/user/actions';
import useModals from '../../hooks/useModals';
import { fetchUserRegion } from '../../store/user/actions';

import AddCreditsModal from '../AddCredits';
import ConfirmationModal from '../reusable/ConfirmationModal';
import NotificationsModal from '../Notifications';
import { calculateCredits } from '../../store/user/selectors';

import storageService from '../../services/storageService';
import useCustomTheme from '../../hooks/useCustomTheme';

const styleIcon = (Icon) => styled(Icon)`
  transition: transform 0.2s;
  :hover {
    cursor: pointer;
    transform: scale(1.1);
  }
`;

const matchesRoute = (route, currentLocation) => {
  // get root part of the possibly nested route, for example get '/invites' part of the '/invites/all' route
  const rootRoutePart = route.to === '/' ? null : route.to.match(/^\/\w+/)[0];
  return (
    (route.to !== '/' && currentLocation.startsWith(rootRoutePart)) ||
    (route.to === '/respondents' && currentLocation.includes('groups'))
  );
};

const BlockingLink = (props) => (
  <StyledTab
    onClick={(e) => {
      // you can implement this any way you want
      if (props.disabled) {
        e.preventDefault();
      }
      // trigger existing onclick
      if (props.onClick) props.onClick(e);
    }}
    {...props}
  />
);

const SingleRoute = ({ Icon, label, to, isActive, currentPath, unreadReportsNum, disabled }) => {
  // const StyledIcon = styleIcon(Icon, disabled);
  return (
    <BlockingLink from={currentPath} to={to} disabled={disabled} isactive={isActive.toString()}>
      {label === 'Reports' && unreadReportsNum !== 0 && <UnreadReports>{unreadReportsNum}</UnreadReports>}
      <Icon />
      <RouteLabel disabled={disabled}>{I18n.t(label)}</RouteLabel>
    </BlockingLink>
  );
};

const Sidebar = () => {
  const domainURL = window.location.hostname.split('.')[0];
  useCustomTheme({ partnerPrefix: domainURL });
  const location = useSelector((state) => state.router.location.pathname);
  const unreadReportsNum = useSelector(selectNumberOfUnreadReports);
  const routesList = useSelector(selectRoutesList);
  const { user, partnerInfo } = useSelector((state) => state.user);
  const notifications = useSelector((state) => state.user.notifications);
  const [customLogo, setCustomLogo] = useState('');
  const newNotifications = notifications.reduce(
    (acc, notification) => (notification.acknowledged ? acc : acc.concat(notification)),
    [],
  );
  const historyNotifications = notifications.reduce(
    (acc, notification) => (notification.acknowledged ? acc.concat(notification) : acc),
    [],
  );

  const dispatch = useDispatch();

  useLayoutEffect(() => {
    const storageCustomLogos = partnerInfo?.logos || '';
    const partnerLogo = storageCustomLogos?.white;
    setCustomLogo(partnerLogo);

    return () => {};
  }, [partnerInfo]);

  const { modalsState, openModal, closeModal } = useModals(['credits', 'logout', 'notifications']);

  const renderRoutes = (routes, currentPath) => {
    return routes.map((route) => (
      <SingleRoute
        key={route.id}
        currentPath={currentPath}
        isActive={matchesRoute(route, currentPath)}
        {...route}
        unreadReportsNum={unreadReportsNum}
      />
    ));
  };

  const Notifications = styleIcon(NotificationsIcon);
  const Logout = styleIcon(LogoutIcon);

  const handleDismiss = (notification) => {
    dispatch(dismissUserNotification(notification));
  };

  return (
    <SidebarContainer>
      <TabsList>
        {/* {!customLogo ? <StyledLogo /> : <LogoImage src={customLogo} alt="Custom Logo" />} */}
        {customLogo?.includes('psytech.svg') ? <StyledLogo /> : <LogoImage src={customLogo} alt="Custom Logo" />}
        <div>{renderRoutes(routesList, location)}</div>
      </TabsList>
      <Footer>
        <Credits
          disabled={user && !user.allowUpdateCredits}
          onClick={() => {
            if (user && user.allowUpdateCredits) openModal('credits');
          }}
        >
          <CurrentBalance>
            <span>{I18n.t('Credits balance:')}</span>
            <span>{calculateCredits(user?.credits, user?.totalFrozenCredits)}</span>
          </CurrentBalance>
          <Plus />
        </Credits>
        <IconsWrapper>
          <NotificationsIconWrapper count={newNotifications.length}>
            <Notifications onClick={() => openModal('notifications')} />
          </NotificationsIconWrapper>
          <Divider />
          <Logout onClick={() => openModal('logout')} />
        </IconsWrapper>
      </Footer>
      {/* TODO find a way to render AddCreditsModal conditionally, taking into account its inner success/failure modals */}
      <AddCreditsModal
        isVisible={modalsState.credits}
        onModalClose={() => closeModal('credits')}
        user={user}
        addCredits={(data, cb) => dispatch(addCredits(data, cb))}
      />
      <ConfirmationModal
        title={I18n.t('Logout')}
        isVisible={modalsState.logout}
        onClose={() => closeModal('logout')}
        description={I18n.t('Are you sure you want to log out?')}
        onConfirm={() => dispatch(logout())}
      />
      {modalsState.notifications && (
        <NotificationsModal
          recent={newNotifications}
          history={historyNotifications}
          onDismiss={handleDismiss}
          onClose={() => closeModal('notifications')}
        />
      )}
    </SidebarContainer>
  );
};

const SidebarContainer = styled.nav`
  position: sticky;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  z-index: 10;
  height: 100vh;
  width: 20rem;
  min-width: 20rem;
  padding: 2.5rem 1rem;
  background-color: ${(props) => props.theme.colors.menuBg};
`;

const TabsList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTab = styled(Link)`
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  text-underline: none;
  text-decoration: none;
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 0.4rem;
  transition: all 0.3s;
  background: ${(props) => (props.isactive === 'true' ? props.theme.colors.mediumBlue : '')};
  opacity: ${(props) => (props.disabled ? 0.7 : 1)};

  svg,
  span {
    transition: all 0.2s;
  }

  :hover {
    background: ${(props) => props.theme.colors.lightBlueHover};
  }

  :active {
    background: ${(props) => props.theme.colors.mediumBlue};
    svg,
    span {
      transform: scale(0.95);
    }
  }
`;

const RouteLabel = styled.span`
  font-weight: 600;
  color: ${(props) => props.theme.colors.white};
  margin-left: 0.5rem;
`;

const UnreadReports = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  min-width: 3.5rem;
  height: 60%;
  border-radius: 4rem;
  background-color: ${(props) => props.theme.colors.mediumBlue};
  color: ${(props) => props.theme.colors.white};
  z-index: 10;
`;

const Credits = styled.div`
  display: flex;
  //flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border: 2px solid rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0.4rem;
  transition: background-color 0.2s;
  opacity: ${(props) => (props.disabled ? 0.7 : 1)};

  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        cursor: pointer;
        background: rgba(255, 255, 255, 0.2);
      }
    `}
`;

const CurrentBalance = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.colors.white};
  letter-spacing: 0.4px;

  span:first-child {
    font-size: ${(props) => props.theme.fontSizes.small};
    font-weight: 600;
  }
  span:last-child {
    font-size: ${(props) => props.theme.fontSizes.normal};
    font-weight: 500;
  }
  opacity: ${(props) => (props.disabled ? 0.7 : 1)};
`;

const IconsWrapper = styled.div`
  display: flex;
  margin-top: 2rem;
  align-items: center;
  justify-content: space-evenly;
  svg {
    width: 100%;
  }
`;

const Divider = styled.div`
  width: 0.1rem;
  height: 4rem;
  background: rgba(255, 255, 255, 0.4);
`;

const Plus = styled(PlusIcon)`
  path {
    fill: ${(props) => props.theme.colors.white};
  }
`;

const NotificationsIconWrapper = styled.div`
  position: relative;
  width: 100%;

  &::after {
    pointer-events: none;
    content: '${({ count }) => count}';
    font-size: 12px;
    font-weight: bold;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateY(-30%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: ${({ count }) => (count > 0 ? 'inline-flex' : 'none')};
    justify-content: center;
    align-items: center;
    color: #fff;
    line-height: 1;
    background-color: red;
  }
`;

const LogoImage = styled.img`
  margin: 20px 0;
  color: ${(props) => props.theme.colors.menuBg};
  scale: 1;
`;

const StyledLogo = styled(LogoSVG)`
  margin-bottom: 30px;
  margin-left: 1rem;
  min-height: 35px;

  svg {
    fill: ${(props) => props.theme.colors.menuBg};
    fill: ${(props) => props.theme.colors.white}; // Ensures that Psytech SVG default logo shows in WHITE

    height: 80px;
    scale: 1.3;
  }
`;

export default Sidebar;
